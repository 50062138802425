import styles from '../styles/components/footer.module.scss';
import Link from 'next/link';
import Image from 'next/image';
import footerLogo from '../public/images/roy-morgan-logo-white.svg';
import footerTop from '../public/images/footer-top.svg';
import { Footer as FooterType } from '../types/footer';
import facebookIcon from '../public/images/icons/facebook.png';
import linkedInIcon from '../public/images/icons/linkedin.png';
import twitterIcon from '../public/images/icons/twitter.png';
import youtubeIcon from '../public/images/icons/youtube.png';
import instagramIcon from '../public/images/icons/instagram.png';
import arrowRight from "../public/images/arrow-right-brand.svg";

interface Props {
  footer: FooterType;
}

function getSocialIcon(socialName: string) {
  switch (socialName.toLowerCase()) {
    case 'facebook':
      return (
        <img
          src={facebookIcon.src}
          alt="Facebook Icon"
          className={styles.social_icon}
        />
      );
    case 'twitter':
      return (
        <img
          src={twitterIcon.src}
          alt="Twitter Icon"
          className={styles.social_icon}
        />
      );
    case 'linkedin':
      return (
        <img
          src={linkedInIcon.src}
          alt="LinkedIn Icon"
          className={styles.social_icon}
        />
      );
    case 'youtube':
      return (
        <img
          src={youtubeIcon.src}
          alt="YouTube Icon"
          className={styles.social_icon}
        />
      );
    case 'instagram':
      return (
        <img
          src={instagramIcon.src}
          alt="Instagram Icon"
          className={styles.social_icon}
        />
      );
  }
}


export default function Footer({ footer }: Props): JSX.Element {
  const footerColOneMenu = footer?.footerColOneMenu;
  const footerColTwoMenu = footer?.footerColTwoMenu;
  const footerColThreeMenu = footer?.footerColThreeMenu;
  const footerConnectMenu = footer?.footerConnectMenu;
  return (
    <footer className={styles.footer_wrapper}>
      <img
        src={footerTop.src}
        alt="Footer Top Shape"
        className={styles.footer_top}
      />
      <div className={styles.footer}>
        <div className="container">
          <div className={styles.row_wrapper}>
            <div className={styles.row_one}>
              <div className={`${styles.row_one_col} ${styles.row_one_col_mobile_logo}`}>
                <Link href="/">

                  {footer?.themeSettings?.footerLogo?.mediaItemUrl ? (
                    <img
                      src={footer?.themeSettings?.footerLogo.mediaItemUrl}
                      alt="Roy Morgan Logo"
                    />
                  ) : null}

                </Link>
              </div>
              <div className={styles.row_one_col}>
                <div className={styles.connect_icon_wrapper}>
                  {footerConnectMenu?.menuItems?.nodes?.map((menuItem, idx) => {
                    return (
                      (<Link
                        key={idx}
                        href={menuItem.path}
                        className={styles.item_mobile}
                        target="_blank">

                        {getSocialIcon(menuItem.label)}

                      </Link>)
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles.row_two}>          
              <div className={`${styles.row_two_col} ${styles.row_two_col_one}`}>
                <Link href="/">

                  {footer?.themeSettings?.footerLogo?.mediaItemUrl ? (
                    <img
                      src={footer?.themeSettings?.footerLogo.mediaItemUrl}
                      alt="Roy Morgan Logo"
                      className={styles.footer_logo}
                    />
                  ) : null}

                </Link>
              </div>
              <div className={`${styles.row_two_col} ${styles.row_two_col_two}`}>
                {footerColOneMenu?.menuItems?.nodes?.map((menuItem, idx) => {
                  return (
                    (<Link
                      key={idx}
                      href={menuItem.path}
                      className={`${styles.row_two_col_one_link}`}>

                      <span className={styles.row_two_col_one_label}>
                        {menuItem.label} 
                        <Image
                          src={arrowRight.src}
                          alt="Read More"
                          height={arrowRight.height}
                          width={arrowRight.width}
                        />
                      </span>
                      <span className={styles.row_two_col_one_description}>{menuItem.description}</span>

                    </Link>)
                  );
                })}
              </div>
              <div className={`${styles.row_two_col} ${styles.row_two_col_three}`}>
                {footerColTwoMenu?.menuItems?.nodes?.map((menuItem, idx) => {
                  return (
                    <Link
                      key={idx}
                      href={menuItem.path}
                      className={`${styles.row_two_col_two_link}`}>
                      {menuItem.label}
                    </Link>
                  );
                })}
              </div>
              <div className={`${styles.row_two_col} ${styles.row_two_col_four}`}>
                {footerColThreeMenu?.menuItems?.nodes?.map((menuItem, idx) => {
                  return (
                    <Link
                      key={idx}
                      href={menuItem.path}
                      className={`${styles.row_two_col_two_link}`}>
                      {menuItem.label}
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
